export const moneyFormat = (value: number): string =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);

export const numberFormat = (value: number): string =>
  new Intl.NumberFormat('en-US').format(value);

/* Taken from here: https://stackoverflow.com/a/55987414 */
export const abbreviatedNumberFormat = (n: number): string => {
  if (n < 1e3) {
    return n.toString();
  } else {
    let m: number;
    let tf: number;
    let l: number;
    let u: string;

    if (n >= 1e3 && n < 1e6) {
      // K
      m = 1000;
      tf = 3;
      l = 1;
      u = 'K';
    } else if (n >= 1e6 && n < 1e9) {
      // M
      m = 1000000;
      tf = 6;
      l = 4;
      u = 'M';
    } else if (n >= 1e9 && n < 1e12) {
      // B
      m = 1000000000;
      tf = 9;
      l = 7;
      u = 'B';
    } else if (n >= 1e12 && n < 1e15) {
      // T
      m = 1000000000000;
      tf = 12;
      l = 10;
      u = 'T';
    }

    return (
      (n / m)
        .toFixed(tf)
        .substring(0, n.toString().length - l)
        .replace(/\.0/, '') + u
    );
  }
};

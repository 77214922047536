import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import type { FC } from 'react';

import Skeleton from 'core/components/Skeleton';
import type User from 'core/models/User';
import { numberFormat } from 'core/utils/formats';

import { DEFAULT_PROFILE_PICTURE_URL } from 'config';
import { profileRoute } from 'routes';

import s from './CreatorResult.module.scss';

type Props = {
  fallback?: boolean;
  user?: User;
};

const CreatorResult: FC<Props> = ({ fallback, user }) => {
  return (
    <div className={s.root}>
      {fallback ? (
        <>
          <div className={s.noLink}>
            <div className={s.profilePicture}>
              <Image
                alt="Profile Picture"
                className={s.fallbackProfilePictureImg}
                src={DEFAULT_PROFILE_PICTURE_URL}
                layout="responsive"
                width="150"
                height="150"
              />
              <Skeleton classNames={{ root: s.fallbackImgSkeleton }} />
            </div>
            <div className={s.details}>
              <div className={s.name}>
                <Skeleton>. . . . . . . . . . . . . . . . . .</Skeleton>
              </div>
              <div className={s.views}>
                <Skeleton>. . . . . . . . . . . .</Skeleton>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Link href={profileRoute(user.username)}>
          <a className={s.link}>
            <div className={s.profilePicture}>
              <Image
                alt="Profile Picture"
                className={s.profilePictureImg}
                src={user.profilePictureUrl}
                layout="responsive"
                width="150"
                height="150"
              />
            </div>
            <div className={s.details}>
              <div className={s.name}>{user.name}</div>
              <div className={s.views}>
                {numberFormat(user.creator.numViews)} views
              </div>
            </div>
          </a>
        </Link>
      )}
    </div>
  );
};

export default CreatorResult;

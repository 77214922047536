import { useRouter } from 'next/router';
import React from 'react';
import type { FC } from 'react';

import CoreSEO from 'core/components/SEO';

import {
  NODE_ENV,
  PROD_DOMAIN,
  SEXWORKER_DOMAIN,
  SITE_NAME,
  SITE_DESCRIPTION,
  TWITTER_USERNAME,
} from 'config';

type Props = {
  canonical?: string;
  description?: string;
  noIndex?: boolean;
  ogImage?: string;
  ogUrl?: string;
  title: string;
  titleSuffix?: string;
  twitterCard?: string;
  twitterSite?: string;
};

const SEO: FC<Props> = ({
  canonical,
  description,
  noIndex,
  ogImage,
  ogUrl,
  title,
  titleSuffix,
  twitterCard,
  twitterSite,
}) => {
  const router = useRouter();

  let _titleSuffix = titleSuffix || SITE_NAME;

  const _title = `${title || _titleSuffix}${
    router.pathname !== '/' ? ` - ${_titleSuffix}` : ''
  }`;

  const _description = description || SITE_DESCRIPTION;

  const _ogImage = ogImage?.includes('http')
    ? ogImage
    : `${NODE_ENV === 'production' ? 'https' : 'http'}://${SEXWORKER_DOMAIN}${
        ogImage || '/img/og-image.png'
      }`;

  return (
    <CoreSEO
      title={_title}
      description={_description}
      noIndex={
        NODE_ENV === 'production' && SEXWORKER_DOMAIN === PROD_DOMAIN
          ? noIndex
          : true
      }
      canonical={`${
        NODE_ENV === 'production' ? 'https' : 'http'
      }://${SEXWORKER_DOMAIN}${canonical || router.asPath}`}
      twitterCard={twitterCard || 'summary_large_image'}
      twitterSite={twitterSite || `@${TWITTER_USERNAME}`}
      ogTitle={_title}
      ogDescription={_description}
      ogUrl={`${
        NODE_ENV === 'production' ? 'https' : 'http'
      }://${SEXWORKER_DOMAIN}${ogUrl || router.asPath}`}
      ogImage={_ogImage}
    />
  );
};

export default SEO;

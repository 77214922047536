import cn from 'classnames';
import React from 'react';
import type { FC, ReactNode } from 'react';

import Button from 'core/components/v2/Button';

import s from './PageContainer.module.scss';

type Props = {
  backButton?: {
    href: string;
    text: string | ReactNode;
  };
  children?: ReactNode;
  className?: string;
  fullWidth?: boolean;
};

const PageContainer: FC<Props> = ({
  backButton,
  children,
  className,
  fullWidth = false,
}) => (
  <div className={cn(s.root, fullWidth && s.fullWidth, className)}>
    {backButton && (
      <div className={s.backButtonContainer}>
        <Button
          classNames={{ button: s.backButtonButton }}
          href={backButton.href}
          text={<>&larr;&nbsp; {backButton.text}</>}
          subtle={true}
        />
      </div>
    )}
    {children}
  </div>
);

export default PageContainer;
